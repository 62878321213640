define('ember-localforage-adapter/utils/cache', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({

    data: _ember['default'].Map.create(),

    clear: function clear() {
      this.data.clear();
    },

    get: function get(namespace) {
      var data = this.data.get(namespace);

      if (!data) {
        return null;
      }

      return data;
    },

    set: function set(namespace, objects) {
      this.data.set(namespace, objects);
    },

    replace: function replace(data) {
      this.clear();

      for (var index in data) {
        this.set(index, data[index]);
      }
    }
  });
});