define('ember-localforage-adapter/serializers/localforage', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].JSONSerializer.extend({

    _shouldSerializeHasMany: function _shouldSerializeHasMany(snapshot, key, relationship) {
      var relationshipType = snapshot.type.determineRelationshipType(relationship, this.store);

      if (this._mustSerialize(key)) {
        return true;
      }

      return this._canSerialize(key) && (relationshipType === 'manyToNone' || relationshipType === 'manyToMany' || relationshipType === 'manyToOne');
    }
  });
});