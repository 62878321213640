define("ember-cli-clipboard/components/copy-button", ["exports", "ember-cli-clipboard/templates/components/copy-button"], function (_exports, _copyButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var CLIPBOARD_EVENTS = ['success', 'error'];

  var _default = Ember.Component.extend({
    layout: _copyButton.default,
    tagName: 'button',
    classNames: ['copy-btn'],
    attributeBindings: ['clipboardText:data-clipboard-text', 'clipboardTarget:data-clipboard-target', 'clipboardAction:data-clipboard-action', 'buttonType:type', 'disabled', 'aria-label', 'title'],

    /**
     * @property {String} buttonType - type attribute for button element
     */
    buttonType: 'button',

    /**
     * @property {Boolean} disabled - disabled state for button element
     */
    disabled: false,

    /**
     * If true - scope event listener to this element
     * If false - scope event listener to document.body (ClipboardJS)
     * @property {Boolean} delegateClickEvent
     */
    delegateClickEvent: true,

    /**
     * Creates new `ClipboardJS` instance
     * @method _createClipboard
     * @private
     * @returns {Object} newly created ClipboardJS object
     */
    _createClipboard: function _createClipboard() {
      var trigger = this.delegateClickEvent ? "#".concat(this.elementId) : this.element;
      return new window.ClipboardJS(trigger);
    },

    /**
     * Registers Ember Actions with ClipboardJS events
     * @method _registerActions
     * @private
     * @param {Object} clipboard - ClipboardJS object
     * @returns {Void}
     */
    _registerActions: function _registerActions(clipboard) {
      var _this = this,
          _arguments = arguments;

      CLIPBOARD_EVENTS.forEach(function (event) {
        clipboard.on(event, function () {
          if (!_this.disabled) {
            var action = _this[event] || function () {};

            if (typeof action === 'string') {
              // eslint-disable-next-line ember/closure-actions
              _this.sendAction.apply(_this, [action].concat(_toConsumableArray(_arguments)));
            } else {
              action.apply(void 0, _toConsumableArray(_arguments));
            }
          }
        });
      });
    },
    didInsertElement: function didInsertElement() {
      var clipboard = this._createClipboard();

      this._registerActions(clipboard);

      Ember.set(this, 'clipboard', clipboard);
    },
    willDestroyElement: function willDestroyElement() {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
    }
  });

  _exports.default = _default;
});