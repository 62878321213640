define('ember-localforage-adapter/adapters/localforage', ['exports', 'ember', 'ember-data', 'ember-localforage-adapter/utils/queue', 'ember-localforage-adapter/utils/cache'], function (exports, _ember, _emberData, _emberLocalforageAdapterUtilsQueue, _emberLocalforageAdapterUtilsCache) {
  exports['default'] = _emberData['default'].Adapter.extend(_ember['default'].Evented, {

    defaultSerializer: 'localforage',
    queue: _emberLocalforageAdapterUtilsQueue['default'].create(),
    cache: _emberLocalforageAdapterUtilsCache['default'].create(),
    caching: 'model',
    coalesceFindRequests: true,

    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    shouldReloadAll: function shouldReloadAll() {
      return true;
    },

    /**
     * This is the main entry point into finding records. The first parameter to
     * this method is the model's name as a string.
     *
     * @method findRecord
     * @param store
     * @param {DS.Model} type
     * @param {Object|String|Integer|null} id
     */
    findRecord: function findRecord(store, type, id) {
      return this._getNamespaceData(type).then(function (namespaceData) {
        var record = namespaceData.records[id];

        if (!record) {
          return _ember['default'].RSVP.reject();
        }

        return record;
      });
    },

    findAll: function findAll(store, type) {
      return this._getNamespaceData(type).then(function (namespaceData) {
        var records = [];

        for (var id in namespaceData.records) {
          records.push(namespaceData.records[id]);
        }

        return records;
      });
    },

    findMany: function findMany(store, type, ids) {
      return this._getNamespaceData(type).then(function (namespaceData) {
        var records = [];

        for (var i = 0; i < ids.length; i++) {
          var record = namespaceData.records[ids[i]];

          if (record) {
            records.push(record);
          }
        }

        return records;
      });
    },

    queryRecord: function queryRecord(store, type, query) {
      var _this = this;

      return this._getNamespaceData(type).then(function (namespaceData) {
        var record = _this._query(namespaceData.records, query, true);

        if (!record) {
          return _ember['default'].RSVP.reject();
        }

        return record;
      });
    },

    /**
     *  Supports queries that look like this:
     *   {
     *     <property to query>: <value or regex (for strings) to match>,
     *     ...
     *   }
     *
     * Every property added to the query is an "AND" query, not "OR"
     *
     * Example:
     * match records with "complete: true" and the name "foo" or "bar"
     *  { complete: true, name: /foo|bar/ }
     */
    query: function query(store, type, _query) {
      var _this2 = this;

      return this._getNamespaceData(type).then(function (namespaceData) {
        return _this2._query(namespaceData.records, _query);
      });
    },

    _query: function _query(records, query, singleMatch) {
      var results = [];

      for (var id in records) {
        var record = records[id];
        var isMatching = false;

        for (var property in query) {
          var queryValue = query[property];

          if (queryValue instanceof RegExp) {
            isMatching = queryValue.test(record[property]);
          } else {
            isMatching = record[property] === queryValue;
          }

          if (!isMatching) {
            break; // all criteria should pass
          }
        }

        if (isMatching) {
          results.push(record);
        }

        if (singleMatch) {
          return results[0];
        }
      }

      return results;
    },

    createRecord: updateOrCreate,

    updateRecord: updateOrCreate,

    deleteRecord: function deleteRecord(store, type, snapshot) {
      var _this3 = this;

      return this.queue.attach(function (resolve) {
        _this3._getNamespaceData(type).then(function (namespaceData) {
          delete namespaceData.records[snapshot.id];

          _this3._setNamespaceData(type, namespaceData).then(function () {
            resolve();
          });
        });
      });
    },

    generateIdForRecord: function generateIdForRecord() {
      return Math.random().toString(32).slice(2).substr(0, 5);
    },

    // private

    _setNamespaceData: function _setNamespaceData(type, namespaceData) {
      var _this4 = this;

      var modelNamespace = this._modelNamespace(type);

      return this._loadData().then(function (storage) {
        if (_this4.caching !== 'none') {
          _this4.cache.set(modelNamespace, namespaceData);
        }

        storage[modelNamespace] = namespaceData;

        return window.localforage.setItem(_this4._adapterNamespace(), storage);
      });
    },

    _getNamespaceData: function _getNamespaceData(type) {
      var _this5 = this;

      var modelNamespace = this._modelNamespace(type);

      if (this.caching !== 'none') {
        var cache = this.cache.get(modelNamespace);

        if (cache) {
          return _ember['default'].RSVP.resolve(cache);
        }
      }

      return this._loadData().then(function (storage) {
        var namespaceData = storage && storage[modelNamespace] || { records: {} };

        if (_this5.caching === 'model') {
          _this5.cache.set(modelNamespace, namespaceData);
        } else if (_this5.caching === 'all') {
          if (storage) {
            _this5.cache.replace(storage);
          }
        }

        return namespaceData;
      });
    },

    _loadData: function _loadData() {
      return window.localforage.getItem(this._adapterNamespace()).then(function (storage) {
        return storage ? storage : {};
      });
    },

    _modelNamespace: function _modelNamespace(type) {
      return type.url || type.modelName;
    },

    _adapterNamespace: function _adapterNamespace() {
      return this.get('namespace') || 'DS.LFAdapter';
    }
  });

  function updateOrCreate(store, type, snapshot) {
    var _this6 = this;

    return this.queue.attach(function (resolve) {
      _this6._getNamespaceData(type).then(function (namespaceData) {
        var serializer = store.serializerFor(type.modelName);
        var recordHash = serializer.serialize(snapshot, { includeId: true });
        // update(id comes from snapshot) or create(id comes from serialization)
        var id = snapshot.id || recordHash.id;

        namespaceData.records[id] = recordHash;

        _this6._setNamespaceData(type, namespaceData).then(function () {
          resolve();
        });
      });
    });
  }
});