define("ember-flatpickr/components/ember-flatpickr", ["exports", "ember-flatpickr/mixins/flatpickr"], function (_exports, _flatpickr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_flatpickr.default, {
    tagName: 'input',
    type: "text",
    attributeBindings: ["placeholder", "type"],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('field', this.element);
      this.setupFlatpickr();
    }
  });

  _exports.default = _default;
});