define('ember-localforage-adapter/utils/queue', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({

    queue: [_ember['default'].RSVP.resolve()],

    attach: function attach(callback) {
      var _this = this;

      var queueKey = this.queue.length;

      this.queue[queueKey] = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.queue[queueKey - 1].then(function () {
          callback(resolve, reject);
        });
      });

      return this.queue[queueKey];
    }
  });
});